import React from "react"
import { Box, Heading, SimpleGrid, useDisclosure } from "@chakra-ui/core"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import TeamMemberItem from "../components/TeamMemberItem"
import makeBackgroundImageArray from "../helpers/makeBackgroundImageArray"

const Team = props => {
  const intl = useIntl()
  const { onOpen, isOpen, onClose } = useDisclosure()
  return (
    <Layout
      location={props.location}
      bg={makeBackgroundImageArray("team")}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
    >
      <Box pt={12} maxW="800px" my={20}>
        <Heading fontSize={["3xl", "5xl", null, "6xl"]} as="h1" fontWeight="bold">
          <FormattedMessage id="team.heading" />
        </Heading>
      </Box>
      <Box>
        <SimpleGrid columns={/*[1, 2, 3, null]*/1} spacing={4} my={6} maxW={600} mx="auto">
          <TeamMemberItem
            name={intl.formatMessage({ id: "team.team_positions.ceo.name" })}
            position={intl.formatMessage({
              id: "team.team_positions.ceo.position",
            })}
            description=""
            image={props.data.johanne.childImageSharp.fluid}
          />
        </SimpleGrid>
      </Box>
    </Layout>
  )
}

export default Team

export const query = graphql`
  {
    johanne: file(relativePath: { eq: "levser.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    placeholder: file(relativePath: { eq: "placeholder.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`
